import api from '../utils/api'

export default {
  update (params) {
    return api.post(`collabpayments`, params)
  },
  getAll (params) {
    return api.get('collabpayments', { params: params })
  },
  get (params) {
    return api.get(`collabpayments/${params}`)
  }
}
