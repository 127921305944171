<template>
    <div>
        <div class="tile is-ancestor">
            <div class="tile is-parent">
                <article class="tile is-child box">
                    <h4 class="title">Платежи</h4>
                    <spinner v-show="loading"></spinner>

                    <table class="table is-striped" v-if="haveItems() && !loading">
                        <thead>
                        <tr>
                            <th>Период</th>
                            <th>Действие</th>
                            <th>Отчет за период</th>
                        </tr>
                        </thead>

                        <tbody>
                            <tr v-for="item in items">
                                <td>{{ getMonth(item.Month) }}&nbsp;{{ item.Year }}</td>
                                <td>
                                    <button v-if="item.open" class="button" v-on:click="closePeriod(item)">Счет оплачен</button>
                                </td>
                                <td>
                                    <button class="button" v-on:click="downloadReport(item)">Скачать отчет</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <pagination
                            @change="changePage"
                            :total-pages="pagination.total"
                            :limit="pagination.limit"
                            :page="pagination.current"
                    ></pagination>
                </article>
            </div>
        </div>
    </div>
</template>

<script>
import collabService from '../../services/collabpayment'
import itemListViewMixin from '../../mixins/itemListView'
import moment from 'moment'
import 'moment/locale/ru'

export default {
  mixins: [itemListViewMixin(collabService, 'data')],
  methods: {
    getMonth (number) {
      moment().locale('ru')
      let month = moment().month(number - 1)
      return month.format('MMMM', 'ru').toUpperCase()
    },
    closePeriod (item) {
      if (confirm('Подтвердите действие')) { // eslint-disable-line no-undef
        collabService.update(item).then(() => {
          item.open = false
        })
      }
    },
    downloadReport (item) {
      let date = moment().month(item.Month - 1).year(item.Year).format('YMMDD')
      let url = `/api/collabpayments/${date}`
      window.open(url, '_blank')
    }
  }
}
</script>
